import React,{useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';



function TopHeader(){

  const { t, i18n } = useTranslation();

  let DefaultLan = localStorage.getItem('DefaultLan','中国');
  if(!DefaultLan){
    DefaultLan = '中国';
  }
  let DefaultStrLan = localStorage.getItem('DefaultStrLan','zh');
  if(!DefaultStrLan){
    DefaultStrLan = 'zh';
  }
  let DefaultLanIcon = localStorage.getItem('DefaultLanIcon','img/language/zh.svg');
  if(!DefaultLanIcon){
    DefaultLanIcon = 'img/language/zh.svg';
  }
  const [currentLanguage, setCurrentLanguage] = useState(DefaultLan);
  const [currentSrtLanguage, setCurrentSrtLanguage] = useState(DefaultStrLan);
  const [currentLanguageIcon, setCurrentLanguageIcon] = useState(DefaultLanIcon);

  useEffect(() => {
    i18n.changeLanguage(DefaultStrLan);
  },[DefaultStrLan]);


  const changeLanguage = function(event){
      if(event.target.closest("a")){
        let el = event.target.closest("a");
        let lang = el.getAttribute("data-lang");
        i18n.changeLanguage(lang);

        if(lang == "en"){
          saveLanguage("en", "English", "img/language/en.svg");

        }else if(lang == "ru"){
          saveLanguage("ru", "Русский", "img/language/ru.svg");

        }else if(lang == "zh"){
          saveLanguage("zh", "中国", "img/language/zh.svg");

        }else if(lang == "es"){
          saveLanguage("es", "Español", "img/language/es.svg");


        }else if(lang == "id"){
          saveLanguage("id", "Indonesian", "img/language/id.svg");

        }else if(lang == "hi"){
          saveLanguage("hi", "हिंदी", "img/language/hi.svg");

        }else if(lang == "ar"){
          saveLanguage("ar", "عربى", "img/language/ara.svg");


        }else if(lang == "vi"){
          saveLanguage("vi", "Việt Nam", "img/language/vi.svg");
        }
      }

  }

  const saveLanguage = function(str, lang, iconLink){

          setCurrentSrtLanguage(str);
          setCurrentLanguage(lang);
          setCurrentLanguageIcon(iconLink);

          localStorage.setItem('DefaultLan',lang);
          localStorage.setItem('DefaultStrLan', str);
          localStorage.setItem('DefaultLanIcon',iconLink);
  }
  return (

    <header>
      <div className="container mt_20">
          <div className="row">
            <div className="col-md-10 col-sm-10 col-4">
            </div>
            <div className="col-md-2 col-sm-2 col-8">
              <div className="language_abs">
                <div className="dropdown">
                  <button className="dropbtn"><img className="main_lang_flag" alt="" src={currentLanguageIcon} />  {currentLanguage}</button>
                  <div className="dropdown-content" onClick={changeLanguage}>
                    <a data-lang="en" style={{display: (currentSrtLanguage == 'en') ? "none" : "block"}} href="javascript:void"><img alt="" src="img/language/en.svg" />  English</a>

                    <a data-lang="zh" style={{display: (currentSrtLanguage == 'zh') ? "none" : "block"}} href="javascript:void"><img alt="" src="img/language/zh.svg" />  中国</a>

                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </header>
  )
}

export default  TopHeader;