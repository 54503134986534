import React, { Component } from 'react';
import Frame from "../../components/frame";
import TopHeader from '../../components/TopHeader';
import {withRouter} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import NihaoBNB from "../../contracts/NihaoBNB.json";
import getWeb3 from "../../getWeb3";
// Internationalization
import { withTranslation } from 'react-i18next';
const defaultAmountArr = ['0.5','1','2','3','5'];

class Home extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading:true,
        web3: null,
        accounts: null,
        contract: null,
        contractAddress: null,
        sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
        userInfo:{},
        TotalBonus:0,
        totalWithdrawn:0,
        totalDeposits:0,
        totalInvested:0,
        totalUsers:0,
        networkMain:false,
        YourAddress: '-',
        YourAddressfull: null,
        amountBNB:null,
        user_referer:'-',
        balance:null,
        adminFee:null,
        adminFee_percentage:5,
        reinvest_percentage:0,
        withdrwal_percentage:0,
        GetDownlineIncomeByUserId:0,
        GetUplineIncomeByUserId:0,
        communityLevels:[],
        sponsorList:[],
        poolDepositTotal:0,
        poolDeposit:0,
        poolQualifierCount:0,
        GetPoolIncome:0,
      }
    }

    componentDidMount = async () => {
      try {
        // Get network provider and web3 instance.
        const web3 = await getWeb3();
  
        // Use web3 to get the user's accounts.
        setInterval(async()=>{
          const accounts = await web3.eth.getAccounts();
         
          if(this.state.accounts !== accounts[0]){
          // Get the contract instance.
          const networkId = await web3.eth.net.getId();
          //alert(networkId);
          //if(networkId !== 56) throw "Please connect Mainnet"; 
          const deployedNetwork = NihaoBNB.networks[networkId];
          const instance = new web3.eth.Contract(
            NihaoBNB.abi,
            deployedNetwork && deployedNetwork.address,
          );   
          
          this.setState({ web3, accounts:accounts[0], contract: instance, contractAddress:deployedNetwork.address}, this.fetchData);
          }
       },1000);

      } catch (error) {
        // Catch any errors for any of the above operations.
        // alert(
        //   error
        // );
        this.setState({networkMain:false});
        console.error(error);
      }
    };

    fetchData = async() => {
      const { accounts, contract } = this.state;

      console.log(this.state.contractAddress);

      let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
      let YourAddressfull = accounts;
      let userInfo = await contract.methods.users(accounts).call();
      userInfo.amount = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
      userInfo.amount = parseFloat(userInfo.amount).toFixed(5);
      userInfo.referrerBonus = this.state.web3.utils.fromWei(userInfo.referrerBonus, 'ether');
      userInfo.referrerBonus = parseFloat(userInfo.referrerBonus).toFixed(5);
      userInfo.totalWithdrawn = this.state.web3.utils.fromWei(userInfo.totalWithdrawn, 'ether');
      userInfo.totalWithdrawn = parseFloat(userInfo.totalWithdrawn).toFixed(5);
      let user_referer = '-';
      if(userInfo.checkpoint != undefined && userInfo.checkpoint > 0){
        user_referer = userInfo.referrer;
        user_referer = user_referer.slice(0, 10) + '.....' + user_referer.slice(user_referer.length-5, user_referer.length);
      }
      
      let TotalBonus = await contract.methods.TotalBonus(accounts).call();
      TotalBonus = this.state.web3.utils.fromWei(TotalBonus, 'ether');
      TotalBonus = parseFloat(TotalBonus).toFixed(5);
      let totalWithdrawn = await contract.methods.totalWithdrawn().call();
      totalWithdrawn = this.state.web3.utils.fromWei(totalWithdrawn, 'ether');
      totalWithdrawn = parseFloat(totalWithdrawn).toFixed(5);
      let totalDeposits = await contract.methods.totalDeposits().call();
      totalDeposits = parseFloat(totalDeposits).toFixed(5);
      let totalInvested = await contract.methods.totalInvested().call();
      totalInvested = this.state.web3.utils.fromWei(totalInvested, 'ether');
      totalInvested = parseFloat(totalInvested).toFixed(5);
      let totalUsers = await contract.methods.totalUsers().call();

      let GetPoolIncome = await contract.methods.GetPoolIncome(accounts).call();
      GetPoolIncome = this.state.web3.utils.fromWei(GetPoolIncome[0], 'ether');
      GetPoolIncome = parseFloat(GetPoolIncome).toFixed(5);

      let poolDepositTotal = await contract.methods.poolDepositTotal().call();
      poolDepositTotal = this.state.web3.utils.fromWei(poolDepositTotal, 'ether');
      poolDepositTotal = parseFloat(poolDepositTotal).toFixed(5);
      let poolDeposit = await contract.methods.poolDeposit().call();
      poolDeposit = this.state.web3.utils.fromWei(poolDeposit, 'ether');
      poolDeposit = parseFloat(poolDeposit).toFixed(5);
      let poolQualifierCount = await contract.methods.poolQualifierCount().call();
      
      let balance = TotalBonus;
      let adminFee = balance*this.state.adminFee_percentage/100;
      balance = balance - adminFee;

      let getEligibleWithdrawal = await contract.methods.getEligibleWithdrawal(accounts).call();
      let reinvest_percentage = getEligibleWithdrawal.reivest;
      let withdrwal_percentage = getEligibleWithdrawal.withdrwal;

      let singleUplineBonusTaken = this.state.web3.utils.fromWei(userInfo.singleUplineBonusTaken, 'ether');
      let singleDownlineBonusTaken = this.state.web3.utils.fromWei(userInfo.singleDownlineBonusTaken, 'ether');

      let GetDownlineIncomeByUserId = await contract.methods.GetDownlineIncomeByUserId(accounts).call();
      GetDownlineIncomeByUserId = this.state.web3.utils.fromWei(GetDownlineIncomeByUserId, 'ether');
      GetDownlineIncomeByUserId = GetDownlineIncomeByUserId - singleDownlineBonusTaken;
      GetDownlineIncomeByUserId = parseFloat(GetDownlineIncomeByUserId).toFixed(5);

      let GetUplineIncomeByUserId = await contract.methods.GetUplineIncomeByUserId(accounts).call();
      GetUplineIncomeByUserId = this.state.web3.utils.fromWei(GetUplineIncomeByUserId, 'ether');
      GetUplineIncomeByUserId = GetUplineIncomeByUserId - singleUplineBonusTaken;
      GetUplineIncomeByUserId = parseFloat(GetUplineIncomeByUserId).toFixed(5);

      this.setState({ 
        userInfo,
        TotalBonus,
        totalWithdrawn,
        totalDeposits,
        totalInvested,
        totalUsers,
        poolDepositTotal,
        poolDeposit,
        poolQualifierCount,
        GetPoolIncome,
        //networkMain:true,
        YourAddress:YourAddress,
        YourAddressfull:YourAddressfull,
        user_referer:user_referer,
        balance,
        adminFee,
        reinvest_percentage,
        withdrwal_percentage,
        GetUplineIncomeByUserId,
        GetDownlineIncomeByUserId,
        loading:false
      },async()=>{
        const { accounts, contract } = this.state;
        let getEligibleLevelCountForUpline = await contract.methods.getEligibleLevelCountForUpline(accounts).call();
        let uplineCount = getEligibleLevelCountForUpline.uplineCount;
        let downlineCount = getEligibleLevelCountForUpline.downlineCount;
        let communityLevels = [];
        let upline_users = [];
        let downline_users = [];
        let current_user = accounts;
        let userInfo = await contract.methods.users(current_user).call();
        for(let i=1;i<=uplineCount;i++){
          if(current_user == userInfo.singleUpline) continue;
          current_user = userInfo.singleUpline;
          let emptyAddress = /^0x0+$/.test(current_user);
          if(emptyAddress) continue;
          userInfo = await contract.methods.users(current_user).call();
          let investment = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
          let income = (investment/100).toFixed(5);
          investment = parseFloat(investment).toFixed(5);
          upline_users.push({level:i,levelText:this.props.t('mainContent.Upline-')+i,type:'upline',username:current_user,investment:investment,income:income});
        }
        upline_users.sort((a, b) => b.level>a.level? 1 : -1);
        upline_users.map(function(val, index){
          communityLevels.push(val);
        });
        let currentUserInfo = await contract.methods.users(accounts).call();
        let investment = this.state.web3.utils.fromWei(currentUserInfo.amount, 'ether');
        let income = (investment/100).toFixed(5);
        income = (0).toFixed(5);
        investment = parseFloat(investment).toFixed(5);
        let main_user = {level:0,levelText:this.props.t('mainContent.You'),type:'main_user',username:accounts,investment:investment,income:income};
        communityLevels.push(main_user);
        current_user = accounts;
        userInfo = await contract.methods.users(current_user).call();
        for(let i=1;i<=downlineCount;i++){
          if(current_user == userInfo.singleDownline) continue;
          current_user = userInfo.singleDownline;
          let emptyAddress = /^0x0+$/.test(current_user);
          if(emptyAddress) continue;
          userInfo = await contract.methods.users(current_user).call();
          let investment = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
          let income = (investment/100).toFixed(5);
          investment = parseFloat(investment).toFixed(5);
          downline_users.push({level:i,levelText:this.props.t('mainContent.Downline-')+i,type:'downline',username:current_user,investment:investment,income:income});
        }
        downline_users.map(function(val, index){
          communityLevels.push(val);
        });

        let sponsorList = [];
        let count = 0;
        for(let i=0;i<6;i++){
          let referral_stage = await contract.methods.referral_stage(accounts,i).call();
          let _investment = this.state.web3.utils.fromWei(referral_stage._investment, 'ether');
          let ref_bonuses = await contract.methods.ref_bonuses(i).call();
          let percentage_amount = (_investment*ref_bonuses/100).toFixed(5);
          _investment = parseFloat(_investment).toFixed(5);
          let _noOfUser = referral_stage._noOfUser;

          if(i == 0){
            count = _noOfUser;
          }
          let status = '';
          let statusEn = '';
          if(i>1 && count<4){
            statusEn = 'Unqualified';
            status = this.props.t('mainContent.Unqualified');
          }
          sponsorList.push({statusEn:statusEn,status:status,level:i+1,_investment:_investment,_noOfUser:_noOfUser,percentage_amount:percentage_amount});
        }
        

        this.setState({communityLevels, sponsorList});
        // console.log(communityLevels);
        
        // console.log(sponsorList);
         
      });     
    }

    doJoinNow = async () => {
      //const weiValue = this.state.web3.utils.toWei('1', 'ether');
      //const etherValue = this.state.web3.utils.fromWei('1000000000000000000', 'ether');
      const { accounts, contract } = this.state;
      let sponsor_address = this.state.sponsor_address;
      let userInfo = await contract.methods.users(accounts).call();
      if(userInfo.checkpoint != undefined && userInfo.checkpoint > 0){
        sponsor_address = userInfo.referrer;
      }
      else if(!sponsor_address){
        this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
        this.setState({sponsor_addressError:true});
        return false;
      }

      if(!this.state.amountBNB){
        this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' })
        this.setState({amountError:true});
        return false;
      }
      
      let balance = await this.state.web3.eth.getBalance(this.state.accounts);
      let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
      let amountBNB = this.state.amountBNB;
      if(sponsor_address){
        if(balanceEthVal >= amountBNB){
          try {
            this.setState({loading:true});
            this.state.contract.events.NewDeposit((err, eventResult) => {
              console.log('eventResult',eventResult);
              if (!err) {
                let uerAddress = this.state.accounts;
                if(eventResult.returnValues[0] == uerAddress){
                  this.fetchData();
                  this.setState({loading:false});
                  this.props.enqueueSnackbar("Joined Successfully!",{ variant: 'success' });
                }              
              }else{
                console.log(err);
                this.setState({loading:false});
                this.props.enqueueSnackbar('Some Network Error Occurred!');
              }
            });

            let weiValue = this.state.web3.utils.toWei(amountBNB, 'ether');
            let invest = await this.state.contract.methods.invest(sponsor_address).send(
              {
                from: this.state.accounts,
                value:weiValue
              }
            );
            console.log(invest);
          }
          catch(err) {
            this.setState({loading:false});
            this.props.enqueueSnackbar(err,{ variant: 'error' });
          }
        }else{
          this.setState({loading:false});
          this.props.enqueueSnackbar("Insufficient BNB Balance!",{ variant: 'error' });          
        }
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Please enter sponsor address!",{ variant: 'error' });        
      }
    }

    doWithdrawal = async () => {
      if(this.state.TotalBonus > 0){
        this.setState({loading:true});
        this.state.contract.events.Withdrawn((err, eventResult) => {
          console.log('eventResult',eventResult);
          console.log('err',err);
          if (!err) {
            let uerAddress = this.state.accounts;
            if(eventResult.returnValues[0] == uerAddress){
              this.fetchData();
              this.setState({loading:false});
              this.props.enqueueSnackbar("Withdrawal Successfully!",{ variant: 'success' });                
            }
          }else{
            console.log(err);
            this.setState({loading:false});
            this.props.enqueueSnackbar('Some Network Error Occurred!');              
          }        
        });

        let withdrawal = await this.state.contract.methods.withdrawal().send(
          { 
            from: this.state.accounts
          }
        );
        console.log('withdrawal',withdrawal); 
      }else{
        this.props.enqueueSnackbar('Insufficient balance!');
      }
           
    }

    render() {
      const {classes, t} = this.props;
      return (
        <Frame withFooter={false}>
          <TopHeader />
          <div className="pb_20 pt_20">
            <img className="banner_logo" src="img/light_logo.png" alt="" />
          </div>

          <div className="pb_30 pt_30">
            <div className="container">
              <div className="md_container">
                <div className="banner_text">
                  <h2>{t('topHeader.Heading1')}</h2>
                  <p>{t('topHeader.Heading1_Content')}</p>
                </div>
                <div className="banner_text text-right">
                  <h2>{t('topHeader.Heading2')}</h2>
                  <p>{t('topHeader.Heading2_Content')}</p>
                </div>
                <div className="sm_container">
                  <div className="banner_text">
                    <h2>{t('topHeader.Heading3')}</h2>
                    <p>{t('topHeader.Heading3_Content')}</p>
                  </div>
                </div>
                <a target="_blank" class="popup-youtube" href="video/video.mp4">
                  <img class="video-icon" src="img/video-icon.png" alt="" />
                </a>
              </div>
            </div>
          </div>
          {this.state.loading ? (
            <div class="wrapper">
              <div class="flex">
                <span class="circle-1"></span>
                <span class="circle-2"></span>
                <span class="circle-3"></span>
                </div>
            </div>
          ) : (null)}
          <div className="pb_30 pt_60">
            <div className="container">
             
              <div className="md_container">
                <h3 className="heading_text">{t('mainContent.Join_Us_Heading')}</h3>
                <div className="box_line"></div>
                <div className="padding_40">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-8 col-lg-8 col-sm-8 mb_20">
                        {(this.state.userInfo.checkpoint != undefined && this.state.userInfo.checkpoint > 0) ?(
                          <input className="cus_input sm_device_m_b" type="text" placeholder={t('mainContent.Sponsor_Address')}
                            readOnly
                            value={this.state.user_referer}
                            onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                          />
                        ):(
                          <input className="cus_input sm_device_m_b" type="text" placeholder={t('mainContent.Sponsor_Address')}
                            value={this.state.sponsor_address}
                            onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                          />
                        )}
                      </div>
                      <div className="col-md-4 col-lg-4 col-sm-4">
                        <button onClick={this.doJoinNow} className="join_now_btn">{t('mainContent.Join_Now')}</button>
                      </div>
                    </div>
                  </div>
                  <ul className="trx_btn">
                    {defaultAmountArr.map((amount,key) => {return(
                      <li key={key}>
                        <button className={this.state.amountBNB==amount?'active':'inactive'} 
                          onClick={e => this.setState({amountBNB:amount})}>
                          {amount+' BNB'}
                        </button>
                      </li>
                    )
                    })}
                  </ul>
                </div>
                <div className="box_line"></div>
              </div>
            </div>
          </div>

          <div className="pb_20 pt_60">
            <div className="container">
              <div className="all_heading">
                <span className="top_line"></span>
                <h3>{t('mainContent.ID')} - {this.state.YourAddress}</h3>
              </div>
              <div className="row cus_row">
                <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                  <div className="Personal_Details_inner">
                    {/* <h4>{t('mainContent.Community_Level')}</h4> */}
                    <p>{t('mainContent.Upline_Income')}</p>
                    <h5>{this.state.GetUplineIncomeByUserId} {t('mainContent.BNB')}</h5>
                  </div>
                </div>
                <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                  <div className="Personal_Details_inner">
                    {/* <h4>{t('mainContent.Community_Level')}</h4> */}
                    <p>{t('mainContent.Downline_Income')}</p>
                    <h5>{this.state.GetDownlineIncomeByUserId} {t('mainContent.BNB')}</h5>
                  </div>
                </div>
                <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                  <div className="Personal_Details_inner">
                    {/* <h4>{t('mainContent.Affiliate_Level')}</h4> */}
                    <p>{t('mainContent.Sponsor_Income')}</p>
                    <h5>{this.state.userInfo.referrerBonus} {t('mainContent.BNB')}</h5>
                  </div>
                </div>
                <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                  <div className="Personal_Details_inner">
                    {/* <h4>{t('mainContent.Pool')}</h4> */}
                    <p>{t('mainContent.Pool_Income')}</p>
                    <h5>{this.state.GetPoolIncome} {t('mainContent.BNB')}</h5>
                  </div>
                </div>
                {/* <div className="col-md-3 col-sm-3 col-lg-3 col-6">
                  <div className="Personal_Details_inner">
                    <h4>Total Available</h4>
                    <p>Withdrawable Balance</p>
                    <h5>{this.state.balance} BNB</h5>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="pb_20 pt_20">
            <div className="container">
              <div className="md_container">
                <h3 className="heading_text">{t('mainContent.Repurchased_package')}</h3>
                <div className="box_line"></div>
                <div className="padding_40">
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12">
                      <div className="form-group mb_20">
                        <label className="white_label">{t('mainContent.Available')} {t('mainContent.BNB')} ({this.state.adminFee_percentage} % {t('mainContent.Admin_Fee')}: {this.state.adminFee})</label>
                        <input className="cus_input" type="text" readOnly value={this.state.balance} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <div className="form-group mb_20">
                        <label className="white_label">({t('mainContent.Reinvestment_Amount')}) ({t('mainContent.BNB')})</label>
                        <input className="cus_input" type="text" readOnly 
                          value={parseFloat(this.state.reinvest_percentage*this.state.balance/100).toFixed(5)} />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-6">
                      <div className="form-group mb_20">
                        <label className="white_label">{t('mainContent.Withdrawable_Amount')} ({t('mainContent.BNB')})</label>
                        <input className="cus_input" type="text" readOnly
                          value={parseFloat(this.state.withdrwal_percentage*this.state.balance/100).toFixed(5)} />
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb_20">
                    <p className="white_label mb-0">{t('mainContent.Reinvestment_percentage')}: <strong>{this.state.reinvest_percentage}%</strong></p>
                    <p className="white_label">{t('mainContent.Withdrawable_percentage')}: <strong>{this.state.withdrwal_percentage}% </strong></p>
                  </div>
                  <div className="text-center mt_20">
                    <button onClick={this.doWithdrawal} className="grad_btn btn_300">{t('mainContent.Withdrawal')}</button>
                  </div>
                </div>
                <div className="box_line"></div>
              </div>
            </div>
          </div>

          <div className="pb_20 pt_60">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-sm-8 col-lg-8">
                  <div className="all_heading">
                    <span className="top_line"></span>
                    <h3>{t('mainContent.Personal_Details')}</h3>
                  </div>
                  <div className="row cus_row">
                    <div className="col-md-4 col-sm-4 col-6">
                      <div className="Personal_Details_inner">
                        <h4>{t('mainContent.Total_Deposit')}</h4>
                        <h5>{this.state.userInfo.amount} {t('mainContent.BNB')}</h5>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-6">
                      <div className="Personal_Details_inner">
                        <h4>{t('mainContent.Total_Withdrawn')}</h4>
                        <h5>{this.state.userInfo.totalWithdrawn} {t('mainContent.BNB')}</h5>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-4 col-12">
                      <div className="Personal_Details_inner">
                        <h4>{t('mainContent.Referred_By')}</h4>
                        <h5>{this.state.user_referer}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4 col-lg-4">
                  <div className="Community_inner">
                    <span className="span_position">
                      <img src="img/icon1.png" alt="" />
                    </span>
                    <h4>{this.state.totalUsers}</h4>
                    <p>{t('mainContent.Total')} </p>
                    <h5>{t('mainContent.Packages')}</h5>
                  </div>
                  <div className="Community_inner">
                    <span className="span_position">
                      <img src="img/icon2.png" alt="" />
                    </span>
                    <h4>{this.state.totalInvested} {t('mainContent.BNB')}</h4>
                    <p>{t('mainContent.Total')} </p>
                    <h5>{t('mainContent.Invested')}</h5>
                  </div>
                  <div className="Community_inner">
                    <span className="span_position">
                      <img src="img/icon3.png" alt="" />
                    </span>
                    <h4>{this.state.totalWithdrawn}</h4>
                    <p>{t('mainContent.Total')} </p>
                    <h5>{t('mainContent.Withdrawal')}</h5>
                  </div>
                  <div className="Community_inner">
                    <span className="span_position">
                      <img src="img/grow.png" alt="" />
                    </span>
                    <h4>{this.state.poolDepositTotal} {t('mainContent.BNB')}</h4>
                    <p>{t('mainContent.Accumulated')} </p>
                    <h5>{t('mainContent.Pool')}</h5>
                  </div>
                  <div className="Community_inner">
                    <span className="span_position">
                      <img src="img/event.png" alt="" />
                    </span>
                    <h4>{this.state.poolDeposit} {t('mainContent.BNB')} / {this.state.poolQualifierCount}</h4>
                    <p>{t('mainContent.24_hours')} </p>
                    <h5>{t('mainContent.Pool')}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pb_30 pt_30">
            <div className="container">
              <div className="md_container">
                <h3 className="heading_text">{t('mainContent.Upline_downline')}</h3>
                <div className="box_line"></div>
                <div className="padding_40">
                  <div className="table-responsive">
                    <table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>{t('mainContent.Level')}</th>
                          <th>{t('mainContent.User_ID')}</th>
                          <th>{t('mainContent.Investment')}</th>
                          <th>{t('mainContent.Income')}</th>
                        </tr>
                      </thead>
                      <tbody>                        
                        {this.state.communityLevels.length ? (
                          this.state.communityLevels.length>0 ? (
                            this.state.communityLevels.map(function(val, index){
                              let class_name = 'lebel_'+val.level;
                              if(val.level == 0){
                                class_name = 'current_user';
                              }
                              return (
                                <tr key={`cl${index}`} className={class_name}>
                                  <td style={{width:'120px'}}>{val.levelText}</td>
                                  <td style={{width:'50%'}}>{val.username}</td>
                                  <td>{val.investment} {t('mainContent.BNB')}</td>
                                  <td>{val.income} {t('mainContent.BNB')}</td>
                                </tr>
                              )
                            })
                          ):(null)
                        ) : (
                        <tr>
                          <td colSpan="4" className="text-center">{t('mainContent.No_Data_Available')}</td>
                        </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="box_line"></div>
              </div>
            </div>
          </div>

          <div className="pb_30 pt_30">
            <div className="container">
              <div className="md_container">
                <h3 className="heading_text">{t('mainContent.My_direct_tree_team')}</h3>
                <div className="box_line"></div>
                <div className="padding_40">
                  <div className="table-responsive">
                    <table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th> {t('mainContent.Level')} </th>
                          <th> {t('mainContent.Count')} </th>
                          <th> {t('mainContent.Invest_Amount')} </th>
                          <th> {t('mainContent.Percentage_Amount')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.sponsorList.length ? (
                          this.state.sponsorList.length>0 ? (
                            this.state.sponsorList.map(function(val, index){
                              let class_name = 'lebel_'+val.level;
                              if(val.level == 0){
                                class_name = 'current_user';
                              }
                              return (
                                <tr key={`sl${index}`} className={class_name}>
                                  <td style={{width:'120px'}}>
                                  {t('mainContent.Level')}-{val.level}
                                  </td>
                                  <td>{val._noOfUser}</td>
                                  <td>{val._investment} {t('mainContent.BNB')}</td>
                                  <td>{val.percentage_amount} {t('mainContent.BNB')} 
                                    {
                                      val.statusEn?(
                                          <span>
                                            (
                                            <img style={{height:30,width:30,padding:5,marginBottom:5}} src="img/cross.png" />
                                            {/* {t('mainContent.Unqualified')} */}
                                            )
                                          </span>
                                      ):(
                                        <span>
                                          (
                                          <img style={{height:30,width:30,padding:5,marginBottom:5}} src="img/tick.png" />
                                          )
                                        </span>
                                      )
                                    }
                                  </td>
                                </tr>
                              )
                            })
                          ):(null)
                        ) : (
                        <tr>
                          <td colSpan="4" className="text-center">{t('mainContent.No_Data_Available')}</td>
                        </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="box_line"></div>
              </div>
            </div>
          </div>

          <div className="pb_20 pt_60">
            <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-4 col-lg-4">
                <div className="all_heading">
                  <span className="top_line"></span>
                  <h3>{t('mainContent.Read_Our_Plan')}</h3>
                </div> 
                <ul className="pdf_downlad_ul">
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_English.pdf">
                      <span><i className="fa fa-file-pdf-o"></i></span>
                      <h5>{t('mainContent.English_PDF')}</h5>
                      <p>{t('mainContent.Click_here_to_download')}  <i className="fa fa-download"></i></p>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_French.pdf">
                      <span><i className="fa fa-file-pdf-o"></i></span>
                      <h5>{t('mainContent.French_PDF')}</h5>
                      <p>{t('mainContent.Click_here_to_download')}  <i className="fa fa-download"></i></p>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_German.pdf">
                      <span><i className="fa fa-file-pdf-o"></i></span>
                      <h5>{t('mainContent.German_PDF')}</h5>
                      <p>{t('mainContent.Click_here_to_download')}  <i className="fa fa-download"></i></p>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_Indonesian.pdf">
                      <span><i className="fa fa-file-pdf-o"></i></span>
                      <h5>{t('mainContent.Indonesian_PDF')}</h5>
                      <p>{t('mainContent.Click_here_to_download')}  <i className="fa fa-download"></i></p>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_Korean.pdf">
                      <span><i className="fa fa-file-pdf-o"></i></span>
                      <h5>{t('mainContent.Korean_PDF')}</h5>
                      <p>{t('mainContent.Click_here_to_download')}  <i className="fa fa-download"></i></p>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_Russian.pdf">
                      <span><i className="fa fa-file-pdf-o"></i></span>
                      <h5>{t('mainContent.Russian_PDF')}</h5>
                      <p>{t('mainContent.Click_here_to_download')}  <i className="fa fa-download"></i></p>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_Tagalog.pdf">
                      <span><i className="fa fa-file-pdf-o"></i></span>
                      <h5>{t('mainContent.Tagalog_PDF')}</h5>
                      <p>{t('mainContent.Click_here_to_download')}  <i className="fa fa-download"></i></p>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_Japanese.pdf">
                      <span><i className="fa fa-file-pdf-o"></i></span>
                      <h5>{t('mainContent.Japanese_PDF')}</h5>
                      <p>{t('mainContent.Click_here_to_download')}  <i className="fa fa-download"></i></p>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="pdf/Nihaobnb.com_Vietnamese.pdf">
                      <span><i className="fa fa-file-pdf-o"></i></span>
                      <h5>{t('mainContent.Vietnamese_PDF')}</h5>
                      <p>{t('mainContent.Click_here_to_download')}  <i className="fa fa-download"></i></p>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-8 col-sm-8 col-lg-8">
                <div className="all_heading">
                  <span className="top_line"></span>
                  <h3>{t('mainContent.Your_Referral_Link')}</h3>
                </div>
                <img className="referal_img" src="img/referal_img.png" alt="" />
                <h4 className="referal_text word-break">https://nihaobnb.com/{this.state.YourAddress}</h4>
                <div className="text-center mt_20">
                  <CopyToClipboard text={`https://nihaobnb.com/${this.state.YourAddressfull}`}
                    onCopy={() => this.props.enqueueSnackbar("Copied Successfully!",{ variant: 'info' })
                    }>
                    <button className="grad_btn btn_300"><i className="fa fa-clone"></i> {t('mainContent.Copy_Link')}</button>
                  </CopyToClipboard>                  
                </div>
              </div>
            </div>
            </div>
          </div>

          <div>
            <footer className="pb_60 pt_30">
              <div className="container">
                <div className="box_line mb_40"></div>
                <div className="md_container">
                  <img className="footer_logo " src="img/dark_logo.png" alt="" />
                  <p className="copy_text">&copy; {t('mainContent.All_Rights_Reserved')} </p>
                </div>
                
              </div>
            </footer>
          
            <a href="javascript:" id="return-to-top">{t('mainContent.Go_To_Top')}</a>

            <div className="overlay">
              <div className="overlayDoor"></div>
              <div className="overlayContent">
                  <div className="">
                      <img src="img/dark_logo.png?fd" alt="" />
                      <p>{t('mainContent.Loading.....')}</p>
                  </div>
              </div>
            </div>
          </div>
        </Frame>
      );
    }
  }

  const useStyles = {
    root: {
      flexGrow: 1
    }
  }


  export default withRouter(withTranslation()(withStyles(useStyles)(withSnackbar(Home))));
  //export default Home;